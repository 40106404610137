<template>
  <div class="content-layout">
    <h1 id="page-title" class="content__title">User Profile</h1>
    <div class="content__body">
      <div class="profile-grid">
        <div class="profile__header" :style="{ '--bg-color': user.defaultColors.color1 }">
          <img :src="user.userImage" alt="Profile" class="w-24 rounded-full" />
          <div class="profile__headline">{{ user.userName }}, {{ user.userRole }}</div>
          <div>{{ user.userCompany }}</div>
          <div>{{ user.userAddress }}</div>
          <div>{{ user.userEmail }}</div>
          <div>{{ user.userPhone }}</div>
          <div>Theme: {{ user.userDefaultTheme }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettings } from "@/store/user";

export default {
  setup() {
    const user = useSettings();

    return {
      user,
    };
  },
};
</script>

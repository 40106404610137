<template>
  <h1>Overview of our documentation</h1>
  <div>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt est at ex
    porta maximus. Cras ultricies pretium felis vitae laoreet. Nunc quis arcu a risus
    sodales ultricies eu vitae nibh. Etiam pulvinar nibh in mollis malesuada. Maecenas at
    enim eget neque facilisis ornare. Nullam malesuada, massa faucibus malesuada placerat,
    nibh velit gravida arcu, quis iaculis erat risus et massa. Aliquam erat volutpat.
    Pellentesque porta faucibus leo, non auctor libero finibus id. Duis porta ante risus,
    et placerat mauris fermentum ut. Fusce vitae pharetra turpis. In luctus elit hendrerit
    pellentesque rutrum. Integer lacinia semper ligula eget lacinia. Maecenas nec tortor
    et velit hendrerit pretium at eget erat. Morbi non tellus hendrerit arcu lobortis
    ultricies. Duis tincidunt viverra metus sed cursus. In ac odio in turpis tincidunt
    gravida. Nunc non enim turpis. Ut nisl quam, tincidunt quis euismod fringilla, maximus
    feugiat ante. Nullam eu urna varius, lacinia ipsum rutrum, vehicula mauris. Vestibulum
    porta nibh quis erat dictum aliquam. Aliquam commodo iaculis ligula, at hendrerit
    felis dapibus quis. Integer sollicitudin, dui ut porta ornare, orci risus dictum
    lectus, non faucibus ex elit nec sapien. Morbi molestie, turpis at luctus suscipit,
    libero risus lacinia mi, vel feugiat elit erat non mauris. Aliquam ut semper turpis.
    Suspendisse ut orci augue. Nam vel cursus nisl. Fusce eu metus id est fermentum
    iaculis et cursus mi. Duis interdum consequat lectus ut accumsan. Phasellus
    scelerisque laoreet gravida. Nulla congue lacus vel ex sollicitudin posuere. Cras ac
    imperdiet arcu, ut vestibulum metus. Sed sed ullamcorper arcu, a vestibulum velit.
    Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
    Suspendisse sodales eu metus eu vulputate. Aenean at nisl ac nisl pretium ultrices.
    Praesent aliquam dui non sapien tincidunt, at bibendum quam convallis. Maecenas ac
    rhoncus dolor. Quisque in laoreet nulla, ac gravida felis. Aenean porttitor posuere
    arcu eget laoreet. Cras vestibulum ipsum at venenatis gravida. Phasellus convallis
    urna a gravida cursus. Donec nulla elit, ultrices a massa in, lacinia dictum dui.
    Maecenas condimentum suscipit neque ut suscipit. Maecenas a molestie ante. Nam
    volutpat nulla quis justo tristique tempus. Aliquam at elit ullamcorper, ultricies leo
    eu, consectetur arcu. Sed nec sollicitudin neque. Mauris iaculis elit urna, ut
    tincidunt est pharetra in. Vivamus mollis ligula a maximus efficitur. Quisque diam
    orci, sollicitudin nec sem sit amet, volutpat mattis massa. Quisque semper dolor ac
    neque aliquet, auctor ullamcorper turpis volutpat. Orci varius natoque penatibus et
    magnis dis parturient montes, nascetur ridiculus mus. Nulla augue orci, blandit a
    magna ac, sollicitudin condimentum orci. Donec imperdiet accumsan nisl eleifend
    aliquet. Aenean condimentum massa nec facilisis scelerisque. Cras convallis metus
    ipsum, scelerisque luctus lectus egestas eu. Vestibulum eu nisl fermentum, scelerisque
    eros id, aliquam risus. Praesent molestie lorem justo. Mauris faucibus nunc vel massa
    ornare, a vestibulum mauris semper. Integer sollicitudin, massa vitae molestie
    hendrerit, lorem justo malesuada purus, vel sagittis mauris lorem eu augue. Maecenas
    vitae malesuada dolor, et eleifend nisl. Nullam efficitur neque vel consequat mattis.
    Donec ac pretium velit, nec fringilla nulla. Nunc maximus convallis nisi viverra
    ullamcorper. In eleifend, felis vel suscipit pharetra, orci felis rhoncus sem,
    scelerisque mollis turpis quam sed tortor.
  </div>
</template>

<script>
import { useSettings } from "@/store/user";
// import { storeToRefs } from 'pinia'
import mixins from "@/components/mixins";

export default {
  mixins: [mixins],
  setup() {
    const user = useSettings();

    // const {  } = storeToRefs(user)

    return { user };
  },
  components: {},
  methods: {},
};
</script>

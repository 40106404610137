import { defineStore } from 'pinia'

// quick and dirty bomb
const debugMode = false;

export const useSettings = defineStore('useSettings', {
  state: () => {
    return {
      isAuthenticated: debugMode,
      backendApi: process.env.VUE_APP_BACKEND,
      userEmail: null,
      userName: null,
      userAddress: null,
      userPhone: null,
      userImage: null,
      userRole: null,
      userCompany: null,
      scanningStations: [],
      scanningCollections: [
        {
          'id': 1,
          'name': 'Collection 7'
        },
      ],
      userDefaultTheme: 'Barcelona',
      userThemes: [
        {
        'name': 'Barcelona',
        'colors': {
            'color1': '#A40047',
            'color2': '#EEBC00',
            'color3': '#004C99',
            'color4': '#3DA5D9',
            'color5': '#73BFB8',
          }
        },
        {
          'name': 'Arsenal',
          'colors': {
            'color1': '#ec0024',
            'color2': '#ce0041',
            'color3': '#113476',
            'color4': '#99824c',
            'color5': '#fefefe',
            }
          }
      ],
      defaultColors: {
        'color1': '#A40047',
        'color2': '#EEBC00',
        'color3': '#004C99',
        'color4': '#3DA5D9',
        'color5': '#73BFB8',
      },
    }
  },
  actions: {
    async getBeaconStations() {
      this.scanningStations = [];
      const res = await fetch(this.backendApi + "/scannertool/beacon");
      const data = await res.json();
     
      data.forEach(i => {
          this.scanningStations.push(i);
      });
    },
  }
})
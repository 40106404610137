<template>
  <div v-show="updateExists" class="p-2 bg-yellow text-black font-bold text-center">
    <button @click="refreshApp">
      An update is available.. Update now by clicking this banner :)
    </button>
  </div>
  <div class="flex min-h-screen">
    <SidebarMenuAkahon />
    <div
      class="py-2 px-8 mobile:w-6/6 tablet:w-6/6 desktop:w-full bg-theme-color3"
      :style="{ '--bg-color': user.defaultColors.color1 }"
    >
      <div class="py-4 h-16 border-b border-gray">
        <AppHeader />
      </div>
      <div v-show="user.isAuthenticated"><router-view /></div>
      <div class="h-16">
        <AppFooter />
      </div>
    </div>
  </div>

  <h2>Choose your default theme:</h2>
  <select class="p-2 mt-2" v-model="user.userDefaultTheme" @change="changeColor()">
    <option v-for="theme in user.userThemes" :value="theme.name" :key="theme.name">
      {{ theme.name }}
    </option>
  </select>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import SidebarMenuAkahon from "@/components/sidebar/AkahonSideMenu.vue";
import update from "@/mixins/update";

import { useSettings } from "@/store/user";
import { ref } from "vue";

export default {
  name: "App",
  setup() {
    const user = useSettings();
    const bgColor = ref("");

    const changeColor = () => {
      const selectedTheme = user.userThemes.find(
        (theme) => theme.name === user.userDefaultTheme
      );
      if (selectedTheme) {
        bgColor.value = selectedTheme.colors.color1;
        user.defaultColors.color1 = selectedTheme.colors.color1;
      }
    };

    changeColor();

    return {
      user,
      bgColor,
      changeColor,
    };
  },
  mixins: [update],
  components: {
    AppHeader,
    SidebarMenuAkahon,
    AppFooter,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.bg-theme-color3 {
  background-color: var(--bg-color) !important;
}
</style>
